module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156442142-2","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UWEB | 誰にでも優しいWebサイトを増やしたい","short_name":"UWEB","start_url":"/","background_color":"#2E396E","theme_color":"#2E396E","display":"standalone","icon":"static/img/common/apple-touch-icon.png","include_favicon":false,"crossOrigin":"anonymous"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-heading-slug","gatsby-remark-embedder","gatsby-remark-code-titles",{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static/","ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1024,"linkImagesToOriginal":false,"quality":100,"withWebp":true}},{"resolve":"gatsby-remark-custom-blocks","options":{"blocks":{"basic":{"classes":"custom-block_basic","title":"optional"},"memo":{"classes":"custom-block_large-icon custom-block_memo","title":"optional"},"warning":{"classes":"custom-block_large-icon custom-block_warning","title":"optional"}}}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":"|","aliases":{"js":"javascript"},"showLineNumbers":true}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
