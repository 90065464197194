const initialState = {
  isDark: false,
  offDark: false
};

const CHANGE_THEME = 'CHANGE_THEME';

export const changeTheme = (isDark, offDark) => ({
  type: CHANGE_THEME, isDark, offDark
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return { ...state, isDark: action.isDark, offDark: action.offDark };
    default:
      return state;
  }
};
